import { configureStore } from "@reduxjs/toolkit";
import chatbotsReducer from "./slices/chatbots";
import clientReducer from "./slices/client";
import openGraphReducer from "./slices/opengraph";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

const store = configureStore({
  reducer: {
    chatbots: chatbotsReducer,
    client: clientReducer,
    openGraph: openGraphReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
