import {
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";

export default function Alert({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div>
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">{title}</h3>
            <div className="mt-2 text-sm text-yellow-700">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AlertError({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          <div className="mt-2 text-sm text-red-700">{children}</div>
        </div>
      </div>
    </div>
  );
}
