import {
  createContext,
  useContext,
  useEffect,
  useState,
  lazy,
  Suspense,
} from "react";
import { Location, useLocation } from "react-router-dom";
import Alert, { AlertError } from "./components/Alert";
import Chat, { LogoHeader, ThinkingDot } from "./Chat";
import { applyTheme, createTheme } from "./themes/base";
import {
  defaultDarkTheme,
  defaultLight,
  defaultLightTheme,
} from "./themes/default";
import uiTestData from "./UiTestData";
import posthog from "posthog-js";
import { v4 } from "uuid";
import useLocalStorage from "./useLocalStorage";
import { useChatbot } from "./hooks";
import { setChallengeHeaders, setChatbotData } from "./slices/chatbots";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppDispatch } from "./store";
import { setClientId } from "./slices/client";
import sdkServer from "./rpc";
const Gate = lazy(() => import("./Gate"));

function getContext(location: Location) {
  const params = new URLSearchParams(location.search);
  const endpoint = params.get("apiHost") // e. g. staging.api.botbrains.io
    ? `http://${params.get("apiHost")}/v1`
    : import.meta.env.VITE_API_ENDPOINT_URL;
  // window.localStorage
  const config = {
    chatbot: params.get("chatbot") || "",
    hideLogo: params.get("hideLogo") == "" || params.get("hideLogo") == "true",
    hideLegal:
      params.get("hideLegal") == "" || params.get("hideLegal") == "true",
    hideSources:
      params.get("hideSources") == "" || params.get("hideSources") == "true",
    hideSuggestions:
      params.get("hideSuggestions") == "" ||
      params.get("hideSuggestions") == "true",
    // editor stores all the data a first API call could return in base64 of json data.
    editor: params.get("editor") || "",
    isInjectedFromBubble:
      params.get("injectedFromBubble") == "" ||
      params.get("injectedFromBubble") == "true",
    endpoint: endpoint,
  };
  return config;
}

function MissingParamsError() {
  useEffect(() => {
    posthog.capture("Missing Params Error", { window: window.location.href });
  }, []);
  return (
    <Alert title={"URL missing query parameters"}>
      <p>
        This URL is not valid, it is missing the{" "}
        <strong>
          <code>chatbot</code>
        </strong>{" "}
        query string parameter. Please go back to the dashboard and copy the
        link again. If you are unsure whats to do, please reach out to{" "}
        <a
          href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
          className="underline link"
        >
          support
        </a>
        .
      </p>
    </Alert>
  );
}

function LoadingPage() {
  return (
    <div
      role="status"
      className="h-[100dvh] w-full flex items-center justify-center"
    >
      <ThinkingDot />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

function isValidChatbot(v: string) {
  return typeof v === "string" && v.length > 0;
}

function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

function getParentDomain(): string | null {
  const referrer = document.referrer;
  if (!referrer) {
    return null; // No referrer information is available
  }

  try {
    const url = new URL(referrer);
    return url.hostname;
  } catch (error) {
    console.error("Invalid referrer URL:", error);
    return null;
  }
}

function decodeBase64ToJSON(base64String: string) {
  // convert URL-safe base64 to standard base64
  let standardBase64 = base64String.replace(/-/g, "+").replace(/_/g, "/");
  // pad
  const padLength = 4 - (standardBase64.length % 4);
  if (padLength < 4) {
    standardBase64 += "=".repeat(padLength);
  }
  let decodedString = atob(standardBase64);
  return JSON.parse(decodedString);
}

const useThemeDetector = () => {
  const getCurrentTheme = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());

  useEffect(() => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    const listener = ({ matches }: { matches: boolean }) =>
      setIsDarkTheme(matches);
    darkThemeMq.addEventListener("change", listener);
    return () => darkThemeMq.removeEventListener("change", listener);
  }, []);
  return isDarkTheme;
};

function Main({ chatbotId, editor }: { chatbotId: string; editor: string }) {
  const context = useContext(UiContext);
  const dispatch = useDispatch<AppDispatch>();
  const [clientId] = useLocalStorage<string>("botbrains_client_id", v4());
  useEffect(() => {
    dispatch(setClientId(clientId));
    const identifierHeaders = { Authorization: `Basic ${btoa(clientId)}` };
    dispatch(
      setChallengeHeaders({ id: chatbotId, headers: identifierHeaders })
    );
  }, [clientId]);
  const { chatbot: data, isLoading, error, challenge } = useChatbot(chatbotId);

  const isDarkTheme = useThemeDetector();
  useEffect(() => {
    const light = data?.themes?.find((t) => t.name == "light");
    const lightTheme = light
      ? createTheme(light.definitions)
      : defaultLightTheme;
    const dark = data?.themes?.find((t) => t.name == "dark");
    const darkTheme = dark ? createTheme(dark.definitions) : defaultDarkTheme;
    applyTheme(isDarkTheme ? darkTheme : lightTheme);
  }, [isLoading, data, isDarkTheme]);

  useEffect(() => {
    if (data != null && "title" in data) {
      document.title = data.title;
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      sdkServer.emit("config.loaded", data);
    }
  }, [data]);

  sdkServer.register("get", "config", (_: undefined) => ({
    ...data,
    ...context,
  }));

  useEffect(() => {
    if (error) {
      console.error("Error fetching data:", error);
      posthog.capture("api_chat_load_failed", {
        error: error,
        window: window.location.href,
      });
    }
  }, [error]);

  useEffect(() => {
    // have too much traffic to by default enable user profiles
    const disallowedIdentifyingUserDomains = [
      "www.weplayhandball.de",
      "www.weplayvolleyball.de",
      "www.weplaybasketball.de",
      "www.handballdirekt.de",
      "www.basketballdirekt.de",
      "www.volleyballdirekt.de",
      "www.hummelonlineshop-muenchen.de",
    ];
    const isIframe = window.self !== window.top;
    const parentDomain = getParentDomain();
    if (isIframe && disallowedIdentifyingUserDomains.includes(parentDomain!)) {
      return;
    }
    posthog.identify(clientId);
  }, [clientId]);

  if (!isLoading && data) if (isLoading) return <LoadingPage />;
  if (error)
    return (
      <AlertError title={"API unavailable"}>
        <p>{error}</p>
      </AlertError>
    );

  if (!data) {
    if (!challenge) {
      return <></>;
    }
    return (
      <Suspense fallback={<LoadingPage />}>
        <Gate
          challengeConfig={challenge}
          clientId={clientId}
          chatbotId={chatbotId}
        />
      </Suspense>
    );
  }

  const showHeader = !context.hideLogo;
  return (
    <div
      className={classNames(
        "font-sans bg-chat overscroll-hidden",
        showHeader ? "h-[calc(100dvh-4rem)]" : "h-[calc(100dvh)]"
      )}
    >
      {showHeader && <LogoHeader src={data.logo_url} />}
      <div className="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
        <Chat
          chatbot={chatbotId}
          logoUrl={data.logo_url}
          suggestions={data.suggestions}
          initialMessages={data.messages}
        />
      </div>
    </div>
  );
}

// function Main({ chatbot, editor }: { chatbot: string; editor: string }) {
//   const [clientId, setClientId] = useLocalStorage<string>(
//     "botbrains/clientId",
//     v4()
//   );
//   const context = useContext(UiContext);
//   const [data, setData] = useState<ChatbotConfiguration | null>(null);
//   const [loading, setLoading] = useState(true);
//   const [apiError, setApiError] = useState("");
//   const [challengeConfig, setChallengeConfig] = useState<ChallengeConfig>({
//     challenge: "",
//     clientId: clientId,
//   });
//   const [challengeResult, setChallengeResult] = useState<ChallengeResult>({
//     headers: new Headers({ Authorization: `Basic ${btoa(clientId)}` }),
//   });
//   const isDarkTheme = useThemeDetector();

//   // useEffect(() => {
//   //   if (data !== null && "themes" in data) {
//   //     const light = data.themes.find((t) => t.name == "light")!;
//   //     const lightTheme = createTheme(light.definitions);
//   //     const dark = data.themes.find((t) => t.name == "dark");
//   //     if (!dark) {
//   //       applyTheme(lightTheme);
//   //       return;
//   //     }
//   //     const darkTheme = createTheme(dark.definitions);
//   //     applyTheme(isDarkTheme ? darkTheme : lightTheme);
//   //   }
//   // }, [data, isDarkTheme]);

//   useEffect(() => {
//     if (data !== null && "title" in data) {
//       document.title = data.title;
//     }
//   }, [data]);
//   useEffect(() => {
//     setLoading(true);
//     setApiError("");
//     if (import.meta.env.MODE == "isolated") {
//       setData(uiTestData);
//       setLoading(false);
//     } else if (editor) {
//       // editor is base64 string replacing the first API call
//       const editorStaticData = decodeBase64ToJSON(editor);
//       console.log("editor carried data");
//       console.log(editorStaticData);
//       setData(editorStaticData);
//       setLoading(false);
//     } else {
//       (
//         request(`${context.endpoint}/chatbot/${chatbot}`, {
//           headers: challengeResult.headers,
//         }) as Promise<ChatbotConfiguration>
//       )
//         .then((json) => {
//           console.log("FETCHED CHATBOT CONTEXT", json);
//           setData(json);
//         })
//         .catch((error) => {
//           console.log("error", error);
//           if (error.challenge) {
//             setChallengeConfig({ ...error, clientId: clientId });
//             return;
//           }
//           // normal error
//           console.error("Error fetching data:", error);
//           setApiError(error.message);
//           posthog.capture("api_chat_load_failed", {
//             error: error.message,
//             window: window.location.href,
//           });
//         })
//         .finally(() => setLoading(false));
//     }
//   }, [
//     context.endpoint,
//     chatbot,
//     editor,
//     concatHeaders(challengeResult.headers),
//     clientId,
//   ]);

//   if (loading) return <LoadingPage />;
//   if (apiError)
//     return (
//       <AlertError title={"API unavailable"}>
//         <p>{apiError}</p>
//       </AlertError>
//     );
//   console.log("challengeConfig", challengeConfig, data);
//   // challengeConfig.challenge &&
//   if (!data) {
//     console.log("GATE RENDER", challengeConfig, data);
//     return (
//       <Suspense fallback={<LoadingPage />}>
//         <Gate
//           challengeConfig={challengeConfig}
//           setResult={setChallengeResult}
//         />
//       </Suspense>
//     );
//   }
//   return (
//     <div className="font-sans h-[calc(100dvh-4rem)] overscroll-hidden">
//       <LogoHeader src={data.logo_url} isShow={!context.hideLogo} />
//       <div className="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
//         <Chat
//           chatbot={chatbot}
//           logoUrl={data.logo_url}
//           suggestions={data.suggestions}
//           initialMessages={data.messages}
//           extraHeaders={challengeResult.headers}
//         />
//       </div>
//     </div>
//   );
// }

export const UiContext = createContext({
  chatbot: "",
  hideLogo: false,
  hideLegal: false,
  hideSources: true,
  hideSuggestions: false,
  editor: "",
  isInjectedFromBubble: false,
  endpoint: undefined,
});

export default function EmbedApp() {
  const dispatch = useAppDispatch();
  const context = getContext(useLocation());
  useEffect(() => {
    if (
      window.location.hostname == "127.0.0.1" ||
      window.location.hostname == "localhost"
    )
      console.log(import.meta.env);
  });

  // Need to wait until the redux store is updated with the overwrite data before rendering the main component.
  const chatbotId = context.chatbot;
  const editor = context.editor;
  const [once, setOnce] = useState(false);

  useEffect(() => {
    if (once) return;
    if (import.meta.env.MODE === "isolated") {
      dispatch(setChatbotData({ id: chatbotId, data: uiTestData }));
    } else if (editor) {
      const editorStaticData = decodeBase64ToJSON(editor);
      dispatch(setChatbotData({ id: chatbotId, data: editorStaticData }));
    }
    setOnce(true);
  }, [dispatch, chatbotId, editor]);

  if (!once) return <LoadingPage />;
  return (
    <UiContext.Provider value={context}>
      {isValidChatbot(chatbotId) ? (
        <Main chatbotId={chatbotId} editor={editor} />
      ) : (
        <MissingParamsError />
      )}
    </UiContext.Provider>
  );
}
