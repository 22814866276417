// see launcher/src/rpc.ts for client implementation
class Server {
  private callbacks: {
    [op: string]: { [type: string]: (payload: any) => any };
  } = {};
  private allowedOrigin: string = "*";

  // Register a function with a name
  register(op: string, type: string, callback: (payload: any) => any): void {
    if (!this.callbacks[op]) {
      this.callbacks[op] = {};
    }
    this.callbacks[op][type] = callback;
  }

  // Start listening for messages from the allowed origin
  listen(origin: string): void {
    this.allowedOrigin = origin;
    window.addEventListener("message", this.handleMessage.bind(this));
  }

  // Emit an event to be handled by the SDK
  emit(event: string, properties: any): void {
    window.parent.postMessage(
      {
        type: "botbrains.event",
        payload: { event, properties },
      },
      "*"
    );
  }

  // Handle a req-res style request by the SDK
  private handleMessage(event: MessageEvent): void {
    if (this.allowedOrigin !== "*")
      if (event.origin !== this.allowedOrigin) return;

    const { op, type, payload } = event.data;

    const opRegister = this.callbacks[op] || {};
    const callback = opRegister[type];
    if (callback) {
      const result = callback(payload);
      event.ports[0].postMessage(result);
      event.ports[0].close();
    }
  }
}

const sdkServer = new Server();
export default sdkServer;
