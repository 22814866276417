/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON, status from the response
 */
function parseJSON(
  response: Response
): Promise<{ status: number; ok: boolean; json: any }> {
  return new Promise((resolve) =>
    response.json().then((json) =>
      resolve({
        status: response.status,
        ok: response.ok,
        json,
      })
    )
  );
}

/**
 * Requests a URL, returning a promise
 * Already contains version prefix /v1
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {Promise}           The request promise
 */
export default function request(url: string, options: RequestInit | undefined) {
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(parseJSON)
      .then((response) => {
        console.log(response)
        if (response.ok) {
          return resolve(response.json);
        } else {
          return reject(response.json);
        }
      })
      .catch((error) =>
        reject({
          isNetworkError: true, // network error
          message: error.message,
          name: error.name,
          stack: error.stack,
        })
      );
  });
}
