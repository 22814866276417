import { FilloutStandardEmbed } from "@fillout/react";
import { useEffect, useRef, useState } from "react";

export default function FilloutFormMessage({ filloutId }: { filloutId: string }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [formSize, setFormSize] = useState({ width: 600 });

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        // const containerHeight = containerRef.current.offsetHeight;
        setFormSize({
          width: containerWidth,
        });
      }
    };

    // Set the initial size
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="w-full max-w-xl mx-auto" ref={containerRef}>
      <div style={formSize}>
        <FilloutStandardEmbed filloutId={filloutId} dynamicResize={true} />
      </div>
    </div>
  );
}
