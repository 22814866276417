// src/useLocalStorage.js
import { useState, useEffect } from 'react';

function useLocalStorage<T>(key: string, initialValue: any): [T, (value: T) => void]{
  // Retrieve an item from localStorage and parse it
  const readValue = () => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  };

  // Use useState to manage the value
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Update localStorage whenever the state changes
  useEffect(() => {
    if (storedValue === undefined) return;
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  }, [key, storedValue]);

  // Return state and the function to update it
  return [storedValue, setStoredValue];
}

export default useLocalStorage;
