import { createParser } from "eventsource-parser";

// Other implementations: https://github.com/search?q=eventsource-parser+chat+stream&type=code
// https://github.com/Bin-Huang/chatbox/blob/236f87bc0443e3f4f8599cf99d2384a19cbb181c/src/packages/llm.ts#L129

export async function handleSSE(
  response: Response,
  onMessage: (messageData: string) => void
) {
  // onMessage: (message: string) => void
  if (!response.ok) {
    const error = await response.json().catch(() => null);
    throw new Error(
      error
        ? JSON.stringify(error)
        : `${response.status} ${response.statusText}`
    );
  }
  if (response.status !== 200) {
    throw new Error(
      `Error from OpenAI: ${response.status} ${response.statusText}`
    );
  }
  if (!response.body) {
    throw new Error("No response body");
  }
  const parser = createParser((event) => {
    if (event.type === "event") {
      onMessage(event.data);
    }
  });
  for await (const chunk of iterableStreamAsync(response.body)) {
    const str = new TextDecoder().decode(chunk);
    parser.feed(str);
  }
}

export async function* iterableStreamAsync(
  stream: ReadableStream
): AsyncIterableIterator<Uint8Array> {
  const reader = stream.getReader();
  try {
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        return;
      } else {
        yield value;
      }
    }
  } finally {
    reader.releaseLock();
  }
}

export async function SSE(
  url: string,
  options: {
    headers: Headers;
    payload: Record<string, any>;
    onData: (option: { data: any; cancel: () => void }) => void;
    onError: (error: Error) => void;
    onEnd: (status: string) => void;
  }
  // payload, // object
  // headers, // object
  // onData, // ?: (option: {data, cancel}) => void,
  // onError, //?: (error: Error) => void,
  // onEnd, //?: () => void,
) {
  // fetch has been canceled
  let status = "pending";
  let hasCancel = false;
  // abort signal for fetch
  const controller = new AbortController();
  const cancel = () => {
    hasCancel = true;
    controller.abort();
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: [...options.headers, ["Content-Type", "application/json"]],
      body: JSON.stringify(options.payload),
      signal: controller.signal,
    });
    await handleSSE(response, (message) => {
      const data = JSON.parse(message);
      console.log("RECV:", data);
      if (data.error) {
        throw new Error(`Error from botBrains: ${JSON.stringify(data)}`);
      }
      if (options.onData) {
        options.onData({ data, cancel });
      }
    });
    status = "succeeded";
  } catch (error: any) {
    // if a cancellation is performed
    // do not throw an exception
    // otherwise the content will be overwritten.
    if (hasCancel) {
      status = "cancelled";
      return;
    }
    status = "failed";
    if (options.onError) {
      options.onError(error);
    }
    throw error;
  } finally {
    if (options.onEnd) {
      options.onEnd(status);
    }
  }
  return cancel;
}