import { useOpenGraphData } from "../hooks";
import { useState } from "react";
/**
 * Function to extract URLs from a Markdown string.
 * It ignores non-Markdown link previews.
 * @param markdown - The Markdown string to search for URLs.
 * @returns An array of URLs found in the Markdown string.
 */
export function extractMarkdownUrls(markdown: string): string[] {
  // matches [text](url) and extracts the URL
  const markdownLinkRegex = /\[.*?\]\((https?:\/\/[^\s)]+)\)/g;

  const urls: string[] = [];

  let match: RegExpExecArray | null;
  while ((match = markdownLinkRegex.exec(markdown)) !== null) {
    if (match[1]) {
      urls.push(match[1]);
    }
  }
  return urls;
}

const LinkPreview = ({ url }: { url: string }) => {
  const [showLogo, setShowLogo] = useState<boolean>(true);
  const { metaData, loading, error } = useOpenGraphData(url);

  if (loading) {
    return <></>;
  }

  if (error || !metaData || !metaData.hg_title) {
    return <></>;
  }

  return (
    <a
      href={metaData.og_url || metaData.hg_url}
      target="_blank"
      rel="noopener noreferrer nofollow"
      aria-label={`Visit External Website (opens in a new tab)`}
    >
      <div className="mt-2 ring-1 rounded-lg ring-gray-200 hover:bg-gray-50 w-full flex flex-col sm:flex-row overflow-hidden">
        <div>
          {showLogo && (
            <img
              src={metaData.hg_image}
              className="object-cover max-h-64  w-full max-w-xl sm:h-full  sm:max-h-48"
              onError={(e) => setShowLogo(false)}
            />
          )}
        </div>
        <div className="px-3 py-2 flex flex-col">
          <p className="font-semibold  text-chat-text line-clamp-2">
            {metaData.hg_title}
          </p>
          <p className="text-sm text-chat-text-light sm:line-clamp-3 line-clamp-2">
            {metaData.hg_description}
          </p>
          <p className="mt-auto text-xs text-chat-text-light line-clamp-1">
            {metaData.hg_url}
          </p>
        </div>
      </div>
    </a>
  );
};

export default LinkPreview;
