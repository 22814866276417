const TagManager = {
  tags: {},

  setTags(newTags: Record<string, any>) {
    const flattenTags = (obj: Record<string, any>, parentKey = "") =>
      Object.keys(obj).reduce((acc, key) => {
        const fullKey = parentKey ? `${parentKey}:${key}` : key;
        const value = obj[key];

        if (value !== null && typeof value === "object") {
          Object.assign(acc, flattenTags(value, fullKey));
        } else {
          acc[fullKey] = value === undefined ? "" : value;
        }

        return acc;
      }, {} as Record<string, any>);

    this.tags = { ...this.tags, ...flattenTags(newTags) };
  },

  getTags() {
    return Object.entries(this.tags).map(([name, value]) => ({ name, value }));
  },

  reset() {
    this.tags = {};
  },
};

export default TagManager;
