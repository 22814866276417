import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import EmbedApp from "./App";
import RedirectPage from "./RedirectPage";
import ErrorBoundary from "./ErrorBoundary";
import { setupMonitoring } from "./sentry";
import { setupAnalytics } from "./analytics";
import { SpeedInsights } from "@vercel/speed-insights/react";
import store from "./store";
import "./i18n";
import "./index.css";
import { Provider } from "react-redux";
import bustStaleImportsAfterRedeploy from "./staleImportsAfterRedeploy";
import sdkServer from "./rpc";

setupMonitoring();
setupAnalytics();

const router = createBrowserRouter([
  {
    path: "/",
    element:
      import.meta.env.VITE_ENVIRONMENT === "local" ? (
        <div>chatbot local dev</div>
      ) : (
        <RedirectPage href="https://www.botbrains.io" />
      ),
  },
  {
    path: "embed",
    element: <EmbedApp />,
  },
]);

sdkServer.listen("*");

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </Provider>
    {/* Send 10% of all events to servers; https://vercel.com/docs/speed-insights/package#samplerate */}
    <SpeedInsights sampleRate={0.01} />
  </React.StrictMode>
);

const lazyLoadRumInit = async () => {
  try {
    const { rum_init } = await import("./rum");
    rum_init();
  } catch (error) {
    console.error("Failed to load rum", error);
  }
};

bustStaleImportsAfterRedeploy();
lazyLoadRumInit();
