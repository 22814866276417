// src/slices/openGraph.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { OpenGraphData } from "../structs";

interface OpenGraphState {
  data: { [url: string]: OpenGraphData | null };
  isLoading: { [url: string]: boolean };
  error: { [url: string]: string | null };
}

const initialState: OpenGraphState = {
  data: {},
  isLoading: {},
  error: {},
};

export const fetchOpenGraphData = createAsyncThunk<
  { url: string; data: OpenGraphData },
  string,
  {
    rejectValue: { url: string; error: string };
  }
>(
  "openGraph/fetchData",
  async (url, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_ENDPOINT_URL}/opengraph?url=${url}`
      );
      const data = await response.json();
      return { url, data };
    } catch (err: any) {
      return rejectWithValue({ url, error: err.message });
    }
  },
  {
    condition: (url, { getState }) => {
      const { openGraph } = getState() as { openGraph: OpenGraphState };
      return !openGraph.isLoading && !openGraph.data[url]; // Only proceed if not already loading
    },
  }
);

const openGraphSlice = createSlice({
  name: "openGraph",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpenGraphData.pending, (state, action) => {
        const url = action.meta.arg;
        state.isLoading[url] = true;
        state.error[url] = null;
      })
      .addCase(
        fetchOpenGraphData.fulfilled,
        (
          state,
          action: PayloadAction<{ url: string; data: OpenGraphData }>
        ) => {
          const { url, data } = action.payload;
          state.data[url] = data;
          state.isLoading[url] = false;
          state.error[url] = null;
        }
      )
      .addCase(
        fetchOpenGraphData.rejected,
        (state, action: PayloadAction<{ url: string; error: string }>) => {
          const { url, error } = action.payload;
          state.isLoading[url] = false;
          state.error[url] = error;
        }
      );
  },
});

export default openGraphSlice.reducer;
