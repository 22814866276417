import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  Suspense,
} from "react";
import { AlertError } from "./components/Alert";
import request from "./api";
import { UiContext } from "./App";
import { Trans, useTranslation } from "react-i18next";
import SourceBadges from "./Badge";
import FilloutFormMessage from "./forms";
import { SSE } from "./stream";
import tagManager from "./tags";
import posthog from "posthog-js";
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
  DocumentDuplicateIcon,
  XMarkIcon,
  CheckIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";
// import MarkdownRenderer from "./Markdown";
const MarkdownRenderer = React.lazy(() => import("./Markdown"));
import { Message, ChatbotMessageChunk } from "./structs";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import { useChatbot } from "./hooks";
import LinkPreview, { extractMarkdownUrls } from "./components/LinkPreview";
import sdkServer from "./rpc";

// TODO(liamvdv): abort handler for request (show "Abort request" button).
// TOOD(liamvdv): timeout request with correct UI updates.

function calculateHeight(lines: number, textarea: HTMLTextAreaElement) {
  const style = window.getComputedStyle(textarea);
  const borderTopWidth = parseFloat(style.borderTopWidth);
  const borderBottomWidth = parseFloat(style.borderBottomWidth);
  const paddingTop = parseFloat(style.paddingTop);
  const paddingBottom = parseFloat(style.paddingBottom);
  const fontSize = parseFloat(style.fontSize);
  const lineHeight =
    style.lineHeight === "normal"
      ? 1.2 * fontSize
      : parseFloat(style.lineHeight);
  const lineTotalHeight =
    lines === 0 ? 0 : lineHeight * lines + paddingTop + paddingBottom;
  return Math.max(
    lineTotalHeight,
    textarea.scrollHeight + borderTopWidth + borderBottomWidth
  );
}

function CopyButton({ text, className }: { text: string; className: string }) {
  const [isCopied, setIsCopied] = useState<string>("idle");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCopied("idle");
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isCopied]);
  return (
    <button
      onClick={async () => {
        try {
          await navigator.clipboard.writeText(text);
          setIsCopied("success");
        } catch (e) {
          setIsCopied("failure");
        }
      }}
      aria-live="assertive"
      aria-label={isCopied === "success" ? "Copied" : "Copy to clipboard"}
    >
      {isCopied === "idle" && <DocumentDuplicateIcon className={className} />}
      {isCopied === "success" && (
        <CheckIcon
          className={className + " text-green-500 hover:text-green-500"}
        />
      )}
      {isCopied === "failure" && (
        <XMarkIcon className={className + " text-red-500 hover:text-red-500"} />
      )}
    </button>
  );
}

interface PromptProps {
  suggestions: string[];
  value: string;
  setValue: (value: string) => void;
  onSubmit: (value: string) => void;
  disabled: boolean;
  showLegal: boolean;
}

function Prompt({
  suggestions,
  value,
  setValue,
  onSubmit,
  disabled,
  showLegal,
}: PromptProps) {
  const { t } = useTranslation();

  const context = useContext(UiContext);
  const { chatbot } = useChatbot(context.chatbot);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const textDisabled = disabled || value.trim().length == 0;
  const sendAndClear = (immediate: string | undefined = undefined) => {
    const v = immediate || value;
    onSubmit(v);
    setValue("");
  };

  const handleTextfieldEnter = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.keyCode === 13 && e.shiftKey === false && !textDisabled) {
      e.preventDefault();
      sendAndClear();
    }
    if (e.keyCode === 13 && disabled) e.preventDefault();
    // we do not prevent the default and corretly bubbles up handler chain
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const adjustHeight = () => {
    // Adjust the height of the textarea based on its content, must set row=1 for default height a single line
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${calculateHeight(
        1,
        textareaRef.current
      )}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  useEffect(() => {
    const observeTarget = textareaRef.current;

    if (observeTarget) {
      const resizeObserver = new ResizeObserver(() => {
        adjustHeight(); // Call your function on resize
      });

      // Start observing the element
      resizeObserver.observe(observeTarget);

      // Cleanup function to disconnect the observer on component unmount
      return () => resizeObserver.disconnect();
    }
  }, [textareaRef.current]);

  const attachmentsEnabled = false;
  return (
    <div className="w-full md:bg-gradient-to-t from-chat from-50% bg-transparent dark:md:bg-vert-dark-gradient z-10">
      <div className="flex flex-col gap-2 bg-chat rounded-t-[32px]">
        {/* Input Form */}
        <form className="w-full">
          {/* Input (MEDIA, TEXT, SEND); use rounded[__px] to not scale rendering on growing. */}
          <div className="rounded-[32px] [&:has(textarea:focus)]:ring-2 ring-inset ring-brand bg-slate-100">
            <div className="flex w-full items-center p-1.5">
              {/* keep items at bottom */}
              <div className="flex items-end w-full gap-1.5">
                {attachmentsEnabled ? (
                  <div className="p-2">
                    <span className="sr-only">Attach documents</span>
                    <PaperClipIcon className="h-6 w-6 text-chat-text" />
                  </div>
                ) : (
                  <div className="p-2 h-6" />
                )}
                {/* flex-grow for spanning the input element. The new flexbox to center the element. */}
                <div className="w-full">
                  <textarea
                    ref={textareaRef}
                    rows={1}
                    id="chat"
                    aria-label="Chat input"
                    tabIndex={0}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleTextfieldEnter}
                    placeholder={t("prompt.placeholder")}
                    className="max-h-52 resize-none text-lg leading-6  mt-1 w-full border-0 outline-none text-chat-text bg-transparent dark:bg-transparent ring-0 focus-visible:ring-0"
                    maxLength={6000}
                    autoFocus
                  />
                </div>

                <button
                  disabled={textDisabled}
                  type="submit"
                  aria-label="Submit Question"
                  onClick={(e) => [e.preventDefault(), sendAndClear()]}
                  className="p-3 text-chat bg-brand hover:bg-brand-hover disabled:bg-transparent disabled:text-chat-text transition-colors disabled:opacity-40 rounded-full"
                >
                  <span className="" data-state="closed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="h-4 w-4"
                      strokeWidth="2"
                    >
                      <path
                        d="M.5 1.163A1 1 0 0 1 1.97.28l12.868 6.837a1 1 0 0 1 0 1.766L1.969 15.72A1 1 0 0 1 .5 14.836V10.33a1 1 0 0 1 .816-.983L8.5 8 1.316 6.653A1 1 0 0 1 .5 5.67V1.163Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
        {/* Legal */}
        <p
          className={`text-xs text-center text-chat-text-light mt-1 mb-2 ${
            showLegal ? "visible" : "hidden"
          }`}
        >
          <Trans
            i18nKey="legal"
            components={{
              tosLink: (
                <a
                  href={
                    chatbot.terms_of_service_url ||
                    import.meta.env.VITE_TERMS_OF_SERVICE_URL
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="underline link"
                />
              ),
              ppLink: (
                <a
                  href={
                    chatbot.privacy_policy_url ||
                    import.meta.env.VITE_PRIVACY_POLICY_URL
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="underline link"
                />
              ),
            }}
          ></Trans>
        </p>
      </div>
    </div>
  );
}

function MessageComponent({
  message,
  rateMessage,
}: {
  message: AnyMessage;
  rateMessage: any;
}) {
  const { role, content, metadata, message_id } = message;
  const { t } = useTranslation();
  const sources = metadata?.sources ?? [];

  if (metadata?.hasOwnProperty("form")) {
    return (
      <div aria-live="polite">
        <FilloutFormMessage filloutId={metadata.form.filloutId} />
      </div>
    );
  } else if (metadata?.hasOwnProperty("html")) {
    return (
      <div aria-live="polite">
        <div className="w-full max-w-xl mx-auto">
          <div dangerouslySetInnerHTML={{ __html: metadata.html }} />
        </div>
      </div>
    );
  } else if (role == "assistant") {
    return (
      <div className="w-full px-1" aria-live="polite">
        <span className="text-sm text-brand">{t("assistant")}</span>
        <div
          className={"prose !max-w-none text-lg flex-shrink-0 overflow-x-auto"}
        >
          {/* We'll use prose here because it provides a nice markdown styling for code blocks. We overwrite most other features such as text size, color, bg here */}
          <Suspense fallback={<div>{content}</div>}>
            <MarkdownRenderer content={content} />
          </Suspense>
          {/* The initial message has not message_id, and should not be rateable. */}
          {!message.isInitial && !message.isGenerating && (
            <div>
              <SourceBadges sources={sources} />
              <div className="flex items-center gap-3 justify-start">
                <button
                  className="opacity-60 hover:text-brand-hover disabled:text-brand disabled:opacity-100"
                  disabled={
                    message.rating === "thumbsDown" || message.isGenerating
                  }
                  onClick={() => rateMessage("thumbsDown")}
                  aria-label="Rate message thumbs down"
                >
                  <HandThumbDownIcon className="h-5 w-5" />
                </button>
                <button
                  className="opacity-60 hover:text-brand-hover disabled:text-brand disabled:opacity-100"
                  disabled={
                    message.rating === "thumbsUp" || message.isGenerating
                  }
                  onClick={() => rateMessage("thumbsUp")}
                  aria-label="Rate message thumbs up"
                >
                  <HandThumbUpIcon className="h-5 w-5" />
                </button>
                <CopyButton
                  className="h-5 w-5 opacity-60 hover:text-brand-hover"
                  text={content}
                  aria-label="Copy message to clipboard"
                />
              </div>
            </div>
          )}
        </div>
        {extractMarkdownUrls(content)
          .slice(0, 1)
          .map((url) => (
            <LinkPreview url={url} key={url} />
          ))}
      </div>
    );
  } else if (role == "user") {
    return (
      <div className="w-full" aria-live="polite">
        <div
          className={
            "prose text-md max-w-xl text-chat bg-brand py-2.5 px-5 flex-shrink-0 float-right rounded-bl-2xl rounded-tl-2xl rounded-tr-xl"
          }
        >
          <p className="whitespace-pre-wrap">{content.trim()}</p>
        </div>
      </div>
    );
  } else {
    return <div>Unknown role: {role}</div>;
  }
}

export function ThinkingDot() {
  return (
    <div className="ml-2 p-2">
      <div className="relative inline-flex">
        <div className="w-3 h-3 bg-brand rounded-full"></div>
        <div className="w-3 h-3 bg-brand rounded-full absolute top-0 left-0 animate-ping"></div>
        <div className="w-3 h-3 bg-brand rounded-full absolute top-0 left-0 animate-pulse"></div>
      </div>
    </div>
  );
}

function Messages({
  messages,
  isLoading,
  onRateMessage,
}: {
  messages: AnyMessage[];
  isLoading: boolean;
  onRateMessage: (message: AnyMessage, rating: string) => void;
}) {
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);

  const isScrolledToBottom = () => {
    const lastMessageElement = lastMessageRef.current;
    if (!lastMessageElement) return true;

    const rect = lastMessageElement.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;

    // Check if the bottom of the last message element is within the viewport
    console.log(rect.bottom, viewportHeight);
    return rect.bottom - 100 <= viewportHeight;
  };

  const scrollToBottom = () =>
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });

  // // scroll to bottom if user is near bottom
  // useEffect(() => {
  //   if (isScrolledToBottom()) {
  //     scrollToBottom();
  //   }
  // }, [messages[messages.length - 1]]);

  // scroll to bottom on new messages once.
  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  return (
    <div
      ref={messagesContainerRef}
      className="flex flex-col gap-3 max-w-3xl mx-2 pt-2"
      onScroll={() => console.log(isScrolledToBottom())}
    >
      {messages.map((message, i) => (
        <MessageComponent
          key={i}
          message={message}
          rateMessage={(rating: string) => onRateMessage(message, rating)}
        />
      ))}
      {isLoading && <ThinkingDot />}
      <div className="h-32 md:h-32 flex-shrink-0" ref={lastMessageRef} />
    </div>
  );
}

// function manualThemeDefinition(theme) {

// }

// function ThemeModal() {
//   const [theme, setTheme] = useState("os");
//   const options = {
//     "light": (<SunIcon />),
//     "dark":  (<MoonIcon />),
//     "os": (<Cog6ToothIcon />)
//   }
//   return <button>
//    {options[theme]}
//   </button>
// }

export function LogoHeader({ src }: { src: string }) {
  const [hasError, setHasError] = useState<boolean>(false);
  return (
    // height-16 forces to set X on bubble
    <header className="flex items-center justify-center h-16 bg-header-bg">
      <div className="flex shrink-0 items-center">
        {!hasError ? (
          <img
            src={src}
            alt="Logo"
            className="object-contain h-12 max-w-48"
            onError={() => setHasError(true)}
          />
        ) : (
          <></>
        )}
      </div>
    </header>
  );
}

interface HumanMessage {
  message_id: string;
  role: "user";
  content: string;
  metadata: any;
}

interface AssistantMessage {
  message_id: string;
  role: "assistant";
  content: string;
  metadata: any;
  timestamp?: string;

  isGenerating?: boolean;
  rating?: string;
}

interface FormMessage {
  role: "form";
  content: string;
  metadata: {
    form: {
      filloutId: string;
    };
    [key: string]: any;
  };
  message_id: string;
}

type AnyMessage = HumanMessage | AssistantMessage | FormMessage;

export default function Chat({
  chatbot,
  logoUrl,
  suggestions,
  initialMessages,
}: {
  chatbot: string;
  logoUrl: string;
  suggestions: string[];
  initialMessages: Message[];
}) {
  const { t } = useTranslation();
  const extraHeaders = useSelector(
    (state: RootState) => state.chatbots.challengeHeaders[chatbot]
  );
  const context = useContext(UiContext);
  const [prompt, setPrompt] = useState<string>("");
  // map message_id iwht uuidv4 to initialMessages
  const [messages, setMessages] = useState<AnyMessage[]>(
    initialMessages.map(
      (m) =>
        ({
          message_id: m.metadata?.message_id ?? uuid(), // for initial message by profile.ui
          isGenerating: false,
          rating: null,
          isInitial: true,
          metadata: {},
          ...m,
        }) as AnyMessage
    )
  );
  const [conversationId, setConversationId] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>("");

  sdkServer.register("set", "message.text", (payload: string) =>
    setPrompt(payload)
  );
  sdkServer.register("do", "message.send", (payload: string | undefined) => {
    handleSend(payload || prompt);
    setPrompt("");
  });
  sdkServer.register("set", "message.tags", (tags: [string, string][]) =>
    tagManager.setTags(Object.fromEntries(tags))
  );
  sdkServer.register("set", "conversation.tags", (tags: [string, string][]) =>
    tagManager.setTags(Object.fromEntries(tags))
  );
  sdkServer.register(
    "get",
    "conversation.id",
    (_: undefined) => conversationId
  );
  // TODO(liamvdv): add "get" "client.id" (undefined) => client_id
  sdkServer.register("do", "analytics.capture", (payload: [string, any]) =>
    posthog.capture(payload[0], payload[1])
  );

  const handleSend = useCallback(
    (value: string) => {
      import("./rum")
        .then((mod) => mod.rum_enable_session_replay())
        .catch(console.log);
      const numMessagesBeforeSend = messages.length;
      const newMessage = {
        role: "user" as const,
        content: value,
        message_id: uuid(),
        metadata: {},
      };
      setMessages((msgs) => [...msgs, newMessage]);

      // const url = getConversationEndpoint();
      const payload = {
        conversation_id: conversationId,
        messages: [...messages, newMessage],
        tags: tagManager.getTags(),
      };
      setLoading(true);
      setApiError("");

      let text = "";
      const url =
        context.endpoint + `/chatbot/${chatbot}/conversations?stream=true`;

      let analyticsProps = {
        isSuggestion: suggestions.includes(value),
        chatbot: context.chatbot,
        error: undefined,
      };
      const onData = ({
        data,
        cancel,
      }: {
        data: ChatbotMessageChunk;
        cancel: () => void;
      }) => {
        setConversationId(data.metadata.conversation_id);

        if (data.text !== undefined) {
          text += data.text;
        }
        setMessages((msgs) => {
          const answer: AnyMessage = {
            metadata: data.metadata,
            role: "assistant" as const,
            content: text,
            isGenerating: true,
            message_id: data.metadata.message_id,
          };
          if (msgs.length == numMessagesBeforeSend + 1) {
            // "append answer"
            return [...msgs, answer];
          } else {
            // msgs.length == numMessagesBeforeSend + 2
            // "update answer"
            return [...msgs.slice(0, -1), answer];
          }
        });
      };

      const onError = (error: any) => {
        console.log(error);
        setApiError(error.message);
        analyticsProps.error = error.message;
      };
      const onEnd = (status: any) => {
        setLoading(false);
        performance.mark("chat_stream_end");
        const measure = performance.measure(
          "chat_message_duration",
          "chat_stream_start",
          "chat_stream_end"
        );
        const props = {
          ...analyticsProps,
          chatbot: context.chatbot,
          stream: true,
          status,
          startTime: measure.startTime,
          duration: measure.duration,
          textLength: text.length, // 0 for errors
          conversationId: conversationId ?? "",
        };
        console.log(props);
        posthog.capture("api_chat_message", props);
        setMessages((msgs) => {
          const last = msgs[msgs.length - 1];
          console.log("last", msgs, last);
          if (last.role !== "assistant")
            throw new Error(
              "Logic error: last message while streaming must be assistant"
            );
          if (last.isGenerating) {
            return [...msgs.slice(0, -1), { ...last, isGenerating: false }];
          } else {
            return msgs;
          }
        });
      };
      performance.mark("chat_stream_start");
      const cancel = SSE(url, {
        headers: new Headers(extraHeaders),
        payload,
        onData,
        onError,
        onEnd,
      });
      return cancel;
    },
    [messages, conversationId, chatbot, context, suggestions]
  );

  const rateMessage = (message: AnyMessage, rating: string) => {
    const payload = {
      conversation_id: conversationId,
      message_id: message.message_id,
      rating: rating,
    };
    request(`${context.endpoint}/messages/${message.message_id}/rating`, {
      method: "POST",
      headers: new Headers({
        ...extraHeaders,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload),
    })
      .then((json) => {
        setMessages((msgs) => {
          const idx = msgs.findIndex((m) => m.message_id == message.message_id);
          const newMessage = { ...msgs[idx], rating };
          return [...msgs.slice(0, idx), newMessage, ...msgs.slice(idx + 1)];
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {apiError && <AlertError title={"API Error"}>{apiError}</AlertError>}
      <div className="mx-auto w-full max-w-3xl overflow-y-scroll no-scrollbar">
        <Messages
          messages={messages}
          isLoading={isLoading}
          onRateMessage={rateMessage}
        />
      </div>

      <div className="absolute bottom-0 left-0 w-full">
        <div className="stretch max-w-3xl px-2 lg:px-0 md:mx-auto">
          {messages.length == 1 && (
            <div className="mx-2 md:mx-auto max-w-3xl mb-2">
              <p className="text-sm text-chat-text-light text-center mb-1">
                {t("suggestions")}
              </p>
              <div className="grid grid-cols-2 gap-2 auto-rows-fr">
                {suggestions.map((suggestion) => {
                  return (
                    <div key={suggestion}>
                      <button
                        onClick={() => handleSend(suggestion)}
                        // ring-2 ring-inset ring-black opacity-60 hover:opacity-100
                        className="border-chat text-chat bg-brand h-full w-full text-left hover:bg-brand-hover px-3 md:px-3.5 py-1.5 md:py-2.5 rounded-lg"
                      >
                        <p className="text-sm lg:text-base">{suggestion}</p>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <Prompt
            suggestions={suggestions}
            value={prompt}
            disabled={isLoading}
            showLegal={!context.hideLegal}
            setValue={setPrompt}
            onSubmit={handleSend}
          ></Prompt>
        </div>
      </div>
    </>
  );
}
