import sdkServer from "../rpc";
import { ThemeDefinitions } from "../structs";

function camelToKebab(camelCaseString: string): string {
  return camelCaseString
    .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2")
    .toLowerCase();
}

export function createTheme(themeDefinitions: ThemeDefinitions) {
  const theme: { [key: string]: string } = {};

  for (const key in themeDefinitions) {
    if (themeDefinitions.hasOwnProperty(key)) {
      const kebabKey = camelToKebab(key);
      theme[`--theme-${kebabKey}`] = themeDefinitions[key];
    }
  }
  return theme;
}

export function applyTheme(theme: Record<string, string>) {
  sdkServer.emit("theme.changed", theme);
  const root = document.documentElement;
  Object.keys(theme).forEach((cssVar) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}
