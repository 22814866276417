import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppSelector } from "./store";
import { fetchChatbotById } from "./slices/chatbots";
import { fetchOpenGraphData } from "./slices/opengraph";

function concatHeaders(headers: Record<string, string>) {
  if (!headers) {
    return "";
  }
  return Object.entries(headers)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([key, value]) => `${key}:${value}`)
    .join("|");
}

const useChatbot = (id: string) => {
  const dispatch = useAppDispatch();

  const chatbot = useAppSelector(
    (state: RootState) => state.chatbots.chatbots[id]
  );
  const isLoading = useAppSelector(
    (state: RootState) => state.chatbots.isLoading[id]
  );
  const error = useAppSelector((state: RootState) => state.chatbots.error[id]);

  const challenge = useAppSelector(
    (state: RootState) => state.chatbots.challenge[id]
  );

  const headers = useSelector(
    (state: RootState) => state.chatbots.challengeHeaders[id]
  );
  const deterministicConstantHeader = concatHeaders(headers);
  const alreadyHasData = !!chatbot;
  useEffect(() => {
    if (!alreadyHasData) {
      // if (!chatbot && !isLoading && !error && !challenge && !headers) {
      dispatch(fetchChatbotById({ id: id, headers: headers }));
    }
    // Do not pass objects or arrays as deps, change identity on every render
  }, [id, alreadyHasData, deterministicConstantHeader, dispatch]);

  return { chatbot, isLoading, error, challenge };
};

const useOpenGraphData = (url: string) => {
  const dispatch = useAppDispatch();
  const metaData = useAppSelector(
    (state: RootState) => state.openGraph.data[url]
  );
  const loading = useAppSelector(
    (state: RootState) => state.openGraph.isLoading[url]
  );
  const error = useAppSelector(
    (state: RootState) => state.openGraph.error[url]
  );

  useEffect(() => {
    if (!metaData && !loading) {
      dispatch(fetchOpenGraphData(url));
    }
  }, [url, metaData, loading, dispatch]);

  return { metaData, loading, error };
};

export { useOpenGraphData, useChatbot };
