import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ChallengeConfig, ChatbotConfiguration } from "../structs";

interface ChatbotsState {
  chatbots: { [id: string]: ChatbotConfiguration };
  isLoading: { [id: string]: boolean };
  error: { [id: string]: string | null };
  challenge: { [id: string]: ChallengeConfig | null };
  challengeHeaders: { [id: string]: Record<string, string> };
}

const initialState: ChatbotsState = {
  chatbots: {},
  isLoading: {},
  error: {},
  challenge: {},
  challengeHeaders: {},
};

interface FetchChatbotByIdParams {
  id: string;
  headers?: Record<string, string>;
}

// Async thunk for fetching chatbot data
export const fetchChatbotById = createAsyncThunk<
  { id: string; data: ChatbotConfiguration },
  FetchChatbotByIdParams,
  {
    rejectValue: {
      id: string;
      error: string | null;
      challenge: ChallengeConfig | null;
    };
  }
>("chatbots/fetchById", async ({ id, headers }, { rejectWithValue }) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_ENDPOINT_URL}/chatbot/${id}`,
      {
        headers: {
          ...headers,
        },
      }
    );
    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        const challenge = (await response.json()) as ChallengeConfig;
        return rejectWithValue({
          id,
          challenge: challenge,
          error: null,
        });
      } else {
        const error = (await response.json()) as { message: string };
        return rejectWithValue({
          id,
          challenge: null,
          error: error.message,
        });
      }
    }
    const data: ChatbotConfiguration = await response.json();
    return { id, data };
  } catch (err: any) {
    return rejectWithValue({ id, error: err.message, challenge: null });
  }
});

const chatbotsSlice = createSlice({
  name: "chatbots",
  initialState,
  reducers: {
    // allow manually overwriting chatbot data
    setChatbotData: (
      state,
      action: PayloadAction<{ id: string; data: ChatbotConfiguration }>
    ) => {
      const { id, data } = action.payload;
      state.chatbots[id] = data;
      state.isLoading[id] = false;
      state.error[id] = null;
      state.challenge[id] = {"challenge": "identifier" as const };
    },
    setChallengeHeaders: (
      state,
      action: PayloadAction<{ id: string; headers: Record<string, string> }>
    ) => {
      const { id, headers } = action.payload;
      state.challengeHeaders[id] = headers;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatbotById.pending, (state, action) => {
        const {
          meta: {
            arg: { id },
          },
        } = action;
        state.isLoading[id] = true;
        state.error[id] = null;
        state.challenge[id] = null;
      })
      .addCase(
        fetchChatbotById.fulfilled,
        (
          state,
          action: PayloadAction<{ id: string; data: ChatbotConfiguration }>
        ) => {
          const { id, data } = action.payload;
          state.isLoading[id] = false;
          state.chatbots[id] = data;
          state.error[id] = null;
        }
      )
      .addCase(
        fetchChatbotById.rejected,
        (
          state,
          action: PayloadAction<{
            id: string;
            error: string | null;
            challenge: ChallengeConfig | null;
          }>
        ) => {
          const { id, error, challenge } = action.payload;
          state.isLoading[id] = false;
          state.error[id] = error;
          state.challenge[id] = challenge;
        }
      );
  },
});

export const { setChatbotData, setChallengeHeaders } = chatbotsSlice.actions;
export default chatbotsSlice.reducer;
