// https://github.com/vitejs/vite/issues/11804
// https://vitejs.dev/guide/build#load-error-handling

// dynamic imports allow code chunking. By default, the file names in the chunking are 
// a file hash of the file contents. This means that if the file contents change, the
// file name changes. This is a problem in redeploys, as the old assets get removed but might be
// referenced by an active session to be loaded later. This will cause the dynamic import to fail.

function bustStaleImportsAfterRedeploy() {
  window.addEventListener("vite:preloadError", (event) => {
    event.preventDefault();
    window.location.reload(); // refresh the page
  });
}
export default bustStaleImportsAfterRedeploy;