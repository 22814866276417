import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Need pluralization? See tutorial
// https://locize.com/blog/react-i18next/

i18n
  // detect user language
  // Reads "lng" query parameter
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {

          legal:
            "By submitting, you agree to our <tosLink>terms of service</tosLink> and <ppLink>privacy policy</ppLink>.",
          "prompt.placeholder": "Send a message",
          "suggestions.toggle.show": "Show suggestions",
          "suggestions.toggle.hide": "Hide suggestions",
          "suggestions": "Suggestions",
          "assistant": "Assistant",
        },
      },
      de: {
        translation: {
          legal:
            "Durch das Absenden einer Nachricht erteilen Sie Zustimmung zu unseren <tosLink>Nutzungsbedingungen</tosLink> und <ppLink>Datenschutzrichtlinien</ppLink>.",
          "prompt.placeholder": "Eine Nachricht senden",
          "suggestions.toggle.show": "Vorschläge einblenden",
          "suggestions.toggle.hide": "Vorschläge ausblenden",
          "suggestions": "Vorschläge",
          "assistant": "Assistent",
        },
      },
    },
  });

export default i18n;
