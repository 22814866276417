import colors from "tailwindcss/colors";
import { createTheme } from "./base";

// Allowed values as per https://tailwindcss.com/docs/customizing-colors
// '#ffffff' hex values, use colors to access tailwind predefined color palette.
export const defaultLight = {
  brand: colors.indigo["500"],
  brandHover: colors.indigo["700"],

  chat: colors.white,
  chatText: colors.slate["700"],
  chatTextLight: colors.slate["500"],

  headerBg: "", // use brand or chat.
};

export const defaultDark = {
  ...defaultLight
};

export const defaultLightTheme = createTheme(defaultLight);
export const defaultDarkTheme = createTheme(defaultLight);
