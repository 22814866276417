import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state type
interface ClientState {
  clientId: string | null;
}

// Read data from local storage
const localStorageData = localStorage.getItem('botbrains_client_id');

const initialState: ClientState = {
  clientId: localStorageData ? JSON.parse(localStorageData) : null,
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientId: (state, action: PayloadAction<string>) => {
      state.clientId = action.payload;
    },
  },
});

export const { setClientId } = clientSlice.actions;

export default clientSlice.reducer;
