import { useContext } from "react";
import { UiContext } from "./App";
import { Source } from "./structs";

function Badge({ source }: { source: Source }) {
  const { name } = source;
  return (
    <span className="inline-flex items-center rounded-md bg-chat text-chat text-xs font-medium">
      <a href={name} target="_blank" rel="noreferrer">
        {name}
      </a>
    </span>
  );
}

export default function SourceBadges({ sources }: { sources: Source[] }) {
  const context = useContext(UiContext);
  const hideSources = (sources || []).length == 0 || context.hideSources;
  if (hideSources) return <></>;
  const uniqueSources = Object.values(
    sources.reduce((acc, obj) => {
      acc[obj.name] = acc[obj.name] || obj; // even though .text_hash would make more semantic sense.
      return acc;
    }, {} as Record<string, Source>)
  );
  return (
    <div className="flex flex-wrap gap-2 mb-2">
      {uniqueSources.map((source, i) => (
        <Badge key={i} source={source} />
      ))}
    </div>
  );
}
